<template>
  <div class="page page--account account">
    <h1>{{ $t('account.account') }}</h1>
    <template v-if="userData">
      <div class="account__info">
        <h2>Info</h2>
        <p>{{ `${userData.user.firstname} ${userData.user.lastname}` }}
          <fa-icon class="button-icon" icon="edit" @click="showEditUser"></fa-icon>
        </p>
        <p>{{ `${userData.user.email}` }}</p>
        <p>{{ `${userData.user.phone}` }}</p>
        <template v-if="userData.user.iscompany">
          <p><b>{{ `${userData.user.company}` }}</b></p>
          <p>{{ `${userData.user.vatcountry} ${userData.user.vat}` }}</p>
        </template>
        <button class="button" @click="resetPassword" v-if="!userData.user.id_facebook && !userData.user.id_google">
          {{ $t('resetpassword.title') }}
        </button>
        <button class="button button--decline" @click="logoutUser">{{ $t('account.logout') }}</button>
        <p v-if="isResetSent" class="success">{{ $t('resetpassword.checkmail') }}</p>
        <p v-if="resetError" class="error">{{ $t('resetpassword.error') }}</p>
      </div>
      <div class="account__addresses">
        <h2>{{ $t('account.addresses') }}</h2>

        <ul class="list">
          <li v-for="item in userData.addresses" :key="'address--'+item.id" @click="editAddress(item)">
            {{ `${item.street} ${item.streetnr} - ${item.zip} ${item.city} (${item.country})` }}
            <br>
            <template v-if="item.defaultaddress"><span
                class="special">{{ $t('account.defaultpickup') }}</span></template>
            <template v-if="item.invoiceaddress"><span
                class="special">{{ $t('account.defaultinvoice') }}</span></template>
            <template v-if="!item.billoaddress"><span class="error">{{ $t('account.addressinvalid') }}</span>
            </template>
          </li>
        </ul>
        <button class="button button--accept" @click="addAddress">{{ $t('account.newaddress') }}</button>
        <h2>{{ $t('account.orders') }} ({{ this.ordersAmount }})</h2>
        <div class="account__ecode">
          <input class="input" id="ecode" type="text"
                 :class="{'error':ecodeError}"
                 :name="Math.round(Math.random() * Date.now())"
                 v-model="ecode" @keypress.enter="submitCode" :placeholder="$t('account.ecode')"
                 autocomplete="new-password"/>
          <button class="button button--accept" :class="{'button--disabled':!ecode}" @click="submitCode">
            {{ $t('account.ecodeAdd') }}
          </button>
        </div>
        <div class="account__orders" id="result-body">
          <div class="account__orders__header">
            <div>{{ $t('account.ordernr') }}</div>
            <div>ID</div>
            <div>{{ $t('account.type') }}</div>
            <div>{{ $t('account.from') }}</div>
            <div>{{ $t('account.to') }}</div>
            <div>{{ $t('account.date') }}</div>
            <div>{{ $t('account.status') }}</div>
          </div>
          <div class="account__orders__content">
            <div class="account__orders__content__order" v-for="item in orders" :key="'order--'+item.ID"
                 @click="showOrder(item.BONNR, item.LANG, item.KLANTVAN)">
              <div>{{ item.BONNR }}</div>
              <div>{{ item.ID }}</div>
              <div>{{ $t('booking.' + item.SOORT) }}</div>
              <div>{{ item.VAN }}</div>
              <div>{{ item.NAAR }}</div>
              <div>{{ item.DAT }}</div>
              <div>
                <span v-if="item.LINKDISPATCH===999999999">{{ $t('account.paymentok') }}</span>
                <span v-else>{{ $t('account.scheduled') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="account__orders__controls" v-if="ordersPages>1">
          <button class="button" @click="prevPage" :class="{'button--disabled':currentPage===1}">
            {{ $t('previous') }}
          </button>
          <button class="button" @click="nextPage"
                  :class="{'button--disabled':currentPage===ordersPages}">{{ $t('next') }}
          </button>
        </div>
      </div>
    </template>
    <div v-else>loading...</div>

    <div class="popup" v-if="newAddress">
      <div class="popup__wrapper">
        <button class="popup__close" @click="onNewAddressCancel">
          <fa-icon icon="times"></fa-icon>
        </button>
        <p class="popup__title">{{ $t('account.newaddress') }}</p>
        <p class="popup__text">{{ $t('account.newaddresstext') }}</p>
        <div class="popup__body">
          <div class="popup__body__field"><label>{{ $t('booking.street') }} + {{ $t('booking.nr') }}</label><input
              type="text" class="large" v-model="newAddress.street"
              :placeholder="$t('booking.street')"><input type="text" class="small"
                                                         v-model="newAddress.streetnr"
                                                         :placeholder="$t('booking.nr')"></div>
          <div class="popup__body__field">
            <label>{{ $t('booking.postal') }} + {{ $t('booking.city') }}</label>
            <input type="text"
                   class="half"
                   v-model="newAddress.zip"
                   :placeholder="$t('booking.postal')">
            <input class="half" type="text" v-model="newAddress.city"
                   :placeholder="$t('booking.city')">

          </div>
          <div class="popup__body__field"><label>{{ $t('account.country') }}</label><select
              v-model="newAddress.country">
            <option value="BE">Belgium</option>
            <option value="FR">France</option>
            <option value="NL">Netherlands</option>
            <option value="LU">Luxembourg</option>
            <option value="DE">Germany</option>
            <option value="AF">Afghanistan</option>
            <option value="AX">Åland Islands</option>
            <option value="AL">Albania</option>
            <option value="DZ">Algeria</option>
            <option value="AS">American Samoa</option>
            <option value="AD">Andorra</option>
            <option value="AO">Angola</option>
            <option value="AI">Anguilla</option>
            <option value="AQ">Antarctica</option>
            <option value="AG">Antigua and Barbuda</option>
            <option value="AR">Argentina</option>
            <option value="AM">Armenia</option>
            <option value="AW">Aruba</option>
            <option value="AU">Australia</option>
            <option value="AT">Austria</option>
            <option value="AZ">Azerbaijan</option>
            <option value="BS">Bahamas</option>
            <option value="BH">Bahrain</option>
            <option value="BD">Bangladesh</option>
            <option value="BB">Barbados</option>
            <option value="BY">Belarus</option>
            <option value="BE">Belgium</option>
            <option value="BZ">Belize</option>
            <option value="BJ">Benin</option>
            <option value="BM">Bermuda</option>
            <option value="BT">Bhutan</option>
            <option value="BO">Bolivia, Plurinational State of</option>
            <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
            <option value="BA">Bosnia and Herzegovina</option>
            <option value="BW">Botswana</option>
            <option value="BV">Bouvet Island</option>
            <option value="BR">Brazil</option>
            <option value="IO">British Indian Ocean Territory</option>
            <option value="BN">Brunei Darussalam</option>
            <option value="BG">Bulgaria</option>
            <option value="BF">Burkina Faso</option>
            <option value="BI">Burundi</option>
            <option value="KH">Cambodia</option>
            <option value="CM">Cameroon</option>
            <option value="CA">Canada</option>
            <option value="CV">Cape Verde</option>
            <option value="KY">Cayman Islands</option>
            <option value="CF">Central African Republic</option>
            <option value="TD">Chad</option>
            <option value="CL">Chile</option>
            <option value="CN">China</option>
            <option value="CX">Christmas Island</option>
            <option value="CC">Cocos (Keeling) Islands</option>
            <option value="CO">Colombia</option>
            <option value="KM">Comoros</option>
            <option value="CG">Congo</option>
            <option value="CD">Congo, the Democratic Republic of the</option>
            <option value="CK">Cook Islands</option>
            <option value="CR">Costa Rica</option>
            <option value="CI">Côte d'Ivoire</option>
            <option value="HR">Croatia</option>
            <option value="CU">Cuba</option>
            <option value="CW">Curaçao</option>
            <option value="CY">Cyprus</option>
            <option value="CZ">Czech Republic</option>
            <option value="DK">Denmark</option>
            <option value="DJ">Djibouti</option>
            <option value="DM">Dominica</option>
            <option value="DO">Dominican Republic</option>
            <option value="EC">Ecuador</option>
            <option value="EG">Egypt</option>
            <option value="SV">El Salvador</option>
            <option value="GQ">Equatorial Guinea</option>
            <option value="ER">Eritrea</option>
            <option value="EE">Estonia</option>
            <option value="ET">Ethiopia</option>
            <option value="FK">Falkland Islands (Malvinas)</option>
            <option value="FO">Faroe Islands</option>
            <option value="FJ">Fiji</option>
            <option value="FI">Finland</option>
            <option value="FR">France</option>
            <option value="GF">French Guiana</option>
            <option value="PF">French Polynesia</option>
            <option value="TF">French Southern Territories</option>
            <option value="GA">Gabon</option>
            <option value="GM">Gambia</option>
            <option value="GE">Georgia</option>
            <option value="DE">Germany</option>
            <option value="GH">Ghana</option>
            <option value="GI">Gibraltar</option>
            <option value="GR">Greece</option>
            <option value="GL">Greenland</option>
            <option value="GD">Grenada</option>
            <option value="GP">Guadeloupe</option>
            <option value="GU">Guam</option>
            <option value="GT">Guatemala</option>
            <option value="GG">Guernsey</option>
            <option value="GN">Guinea</option>
            <option value="GW">Guinea-Bissau</option>
            <option value="GY">Guyana</option>
            <option value="HT">Haiti</option>
            <option value="HM">Heard Island and McDonald Islands</option>
            <option value="VA">Holy See (Vatican City State)</option>
            <option value="HN">Honduras</option>
            <option value="HK">Hong Kong</option>
            <option value="HU">Hungary</option>
            <option value="IS">Iceland</option>
            <option value="IN">India</option>
            <option value="ID">Indonesia</option>
            <option value="IR">Iran, Islamic Republic of</option>
            <option value="IQ">Iraq</option>
            <option value="IE">Ireland</option>
            <option value="IM">Isle of Man</option>
            <option value="IL">Israel</option>
            <option value="IT">Italy</option>
            <option value="JM">Jamaica</option>
            <option value="JP">Japan</option>
            <option value="JE">Jersey</option>
            <option value="JO">Jordan</option>
            <option value="KZ">Kazakhstan</option>
            <option value="KE">Kenya</option>
            <option value="KI">Kiribati</option>
            <option value="KP">Korea, Democratic People's Republic of</option>
            <option value="KR">Korea, Republic of</option>
            <option value="KW">Kuwait</option>
            <option value="KG">Kyrgyzstan</option>
            <option value="LA">Lao People's Democratic Republic</option>
            <option value="LV">Latvia</option>
            <option value="LB">Lebanon</option>
            <option value="LS">Lesotho</option>
            <option value="LR">Liberia</option>
            <option value="LY">Libya</option>
            <option value="LI">Liechtenstein</option>
            <option value="LT">Lithuania</option>
            <option value="LU">Luxembourg</option>
            <option value="MO">Macao</option>
            <option value="MK">Macedonia, the former Yugoslav Republic of</option>
            <option value="MG">Madagascar</option>
            <option value="MW">Malawi</option>
            <option value="MY">Malaysia</option>
            <option value="MV">Maldives</option>
            <option value="ML">Mali</option>
            <option value="MT">Malta</option>
            <option value="MH">Marshall Islands</option>
            <option value="MQ">Martinique</option>
            <option value="MR">Mauritania</option>
            <option value="MU">Mauritius</option>
            <option value="YT">Mayotte</option>
            <option value="MX">Mexico</option>
            <option value="FM">Micronesia, Federated States of</option>
            <option value="MD">Moldova, Republic of</option>
            <option value="MC">Monaco</option>
            <option value="MN">Mongolia</option>
            <option value="ME">Montenegro</option>
            <option value="MS">Montserrat</option>
            <option value="MA">Morocco</option>
            <option value="MZ">Mozambique</option>
            <option value="MM">Myanmar</option>
            <option value="NA">Namibia</option>
            <option value="NR">Nauru</option>
            <option value="NP">Nepal</option>
            <option value="NL">Netherlands</option>
            <option value="NC">New Caledonia</option>
            <option value="NZ">New Zealand</option>
            <option value="NI">Nicaragua</option>
            <option value="NE">Niger</option>
            <option value="NG">Nigeria</option>
            <option value="NU">Niue</option>
            <option value="NF">Norfolk Island</option>
            <option value="MP">Northern Mariana Islands</option>
            <option value="NO">Norway</option>
            <option value="OM">Oman</option>
            <option value="PK">Pakistan</option>
            <option value="PW">Palau</option>
            <option value="PS">Palestinian Territory, Occupied</option>
            <option value="PA">Panama</option>
            <option value="PG">Papua New Guinea</option>
            <option value="PY">Paraguay</option>
            <option value="PE">Peru</option>
            <option value="PH">Philippines</option>
            <option value="PN">Pitcairn</option>
            <option value="PL">Poland</option>
            <option value="PT">Portugal</option>
            <option value="PR">Puerto Rico</option>
            <option value="QA">Qatar</option>
            <option value="RE">Réunion</option>
            <option value="RO">Romania</option>
            <option value="RU">Russian Federation</option>
            <option value="RW">Rwanda</option>
            <option value="BL">Saint Barthélemy</option>
            <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
            <option value="KN">Saint Kitts and Nevis</option>
            <option value="LC">Saint Lucia</option>
            <option value="MF">Saint Martin (French part)</option>
            <option value="PM">Saint Pierre and Miquelon</option>
            <option value="VC">Saint Vincent and the Grenadines</option>
            <option value="WS">Samoa</option>
            <option value="SM">San Marino</option>
            <option value="ST">Sao Tome and Principe</option>
            <option value="SA">Saudi Arabia</option>
            <option value="SN">Senegal</option>
            <option value="RS">Serbia</option>
            <option value="SC">Seychelles</option>
            <option value="SL">Sierra Leone</option>
            <option value="SG">Singapore</option>
            <option value="SX">Sint Maarten (Dutch part)</option>
            <option value="SK">Slovakia</option>
            <option value="SI">Slovenia</option>
            <option value="SB">Solomon Islands</option>
            <option value="SO">Somalia</option>
            <option value="ZA">South Africa</option>
            <option value="GS">South Georgia and the South Sandwich Islands</option>
            <option value="SS">South Sudan</option>
            <option value="ES">Spain</option>
            <option value="LK">Sri Lanka</option>
            <option value="SD">Sudan</option>
            <option value="SR">Suriname</option>
            <option value="SJ">Svalbard and Jan Mayen</option>
            <option value="SZ">Swaziland</option>
            <option value="SE">Sweden</option>
            <option value="CH">Switzerland</option>
            <option value="SY">Syrian Arab Republic</option>
            <option value="TW">Taiwan, Province of China</option>
            <option value="TJ">Tajikistan</option>
            <option value="TZ">Tanzania, United Republic of</option>
            <option value="TH">Thailand</option>
            <option value="TL">Timor-Leste</option>
            <option value="TG">Togo</option>
            <option value="TK">Tokelau</option>
            <option value="TO">Tonga</option>
            <option value="TT">Trinidad and Tobago</option>
            <option value="TN">Tunisia</option>
            <option value="TR">Turkey</option>
            <option value="TM">Turkmenistan</option>
            <option value="TC">Turks and Caicos Islands</option>
            <option value="TV">Tuvalu</option>
            <option value="UG">Uganda</option>
            <option value="UA">Ukraine</option>
            <option value="AE">United Arab Emirates</option>
            <option value="GB">United Kingdom</option>
            <option value="US">United States</option>
            <option value="UM">United States Minor Outlying Islands</option>
            <option value="UY">Uruguay</option>
            <option value="UZ">Uzbekistan</option>
            <option value="VU">Vanuatu</option>
            <option value="VE">Venezuela, Bolivarian Republic of</option>
            <option value="VN">Viet Nam</option>
            <option value="VG">Virgin Islands, British</option>
            <option value="VI">Virgin Islands, U.S.</option>
            <option value="WF">Wallis and Futuna</option>
            <option value="EH">Western Sahara</option>
            <option value="YE">Yemen</option>
            <option value="ZM">Zambia</option>
            <option value="ZW">Zimbabwe</option>
          </select></div>
        </div>
        <div class="popup__controls">
          <button class="popup__btn popup__btn--accept" @click="onNewAddressSubmit">{{ $t('account.save') }}
          </button>
        </div>
      </div>
    </div>

    <div class="popup" v-if="activeAddress">
      <div class="popup__wrapper">
        <button class="popup__close" @click="activeAddress=null">
          <fa-icon icon="times"></fa-icon>
        </button>
        <p class="popup__title">{{ $t('account.editaddress') }}</p>
        <p class="popup__text">{{ $t('account.editaddresstext') }}</p>
        <div class="popup__body">
          <div class="popup__body__field"><label>{{ $t('booking.street') }} + {{ $t('booking.nr') }}</label><input
              type="text" class="large" v-model="activeAddress.street"
              :placeholder="$t('booking.street')"><input type="text" class="small"
                                                         v-model="activeAddress.streetnr"
                                                         :placeholder="$t('booking.nr')"></div>
          <div class="popup__body__field">
            <label>{{ $t('booking.postal') }} + {{ $t('booking.city') }}</label>
            <input type="text"
                   class="half"
                   v-model="activeAddress.zip"
                   :placeholder="$t('booking.postal')">
            <input class="half" type="text" v-model="activeAddress.city"
                   :placeholder="$t('booking.city')">
          </div>
          <div class="popup__body__field"><label>{{ $t('account.country') }}</label><select
              v-model="activeAddress.country">
            <option value="BE">Belgium</option>
            <option value="FR">France</option>
            <option value="NL">Netherlands</option>
            <option value="LU">Luxembourg</option>
            <option value="DE">Germany</option>
            <option value="AF">Afghanistan</option>
            <option value="AX">Åland Islands</option>
            <option value="AL">Albania</option>
            <option value="DZ">Algeria</option>
            <option value="AS">American Samoa</option>
            <option value="AD">Andorra</option>
            <option value="AO">Angola</option>
            <option value="AI">Anguilla</option>
            <option value="AQ">Antarctica</option>
            <option value="AG">Antigua and Barbuda</option>
            <option value="AR">Argentina</option>
            <option value="AM">Armenia</option>
            <option value="AW">Aruba</option>
            <option value="AU">Australia</option>
            <option value="AT">Austria</option>
            <option value="AZ">Azerbaijan</option>
            <option value="BS">Bahamas</option>
            <option value="BH">Bahrain</option>
            <option value="BD">Bangladesh</option>
            <option value="BB">Barbados</option>
            <option value="BY">Belarus</option>
            <option value="BE">Belgium</option>
            <option value="BZ">Belize</option>
            <option value="BJ">Benin</option>
            <option value="BM">Bermuda</option>
            <option value="BT">Bhutan</option>
            <option value="BO">Bolivia, Plurinational State of</option>
            <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
            <option value="BA">Bosnia and Herzegovina</option>
            <option value="BW">Botswana</option>
            <option value="BV">Bouvet Island</option>
            <option value="BR">Brazil</option>
            <option value="IO">British Indian Ocean Territory</option>
            <option value="BN">Brunei Darussalam</option>
            <option value="BG">Bulgaria</option>
            <option value="BF">Burkina Faso</option>
            <option value="BI">Burundi</option>
            <option value="KH">Cambodia</option>
            <option value="CM">Cameroon</option>
            <option value="CA">Canada</option>
            <option value="CV">Cape Verde</option>
            <option value="KY">Cayman Islands</option>
            <option value="CF">Central African Republic</option>
            <option value="TD">Chad</option>
            <option value="CL">Chile</option>
            <option value="CN">China</option>
            <option value="CX">Christmas Island</option>
            <option value="CC">Cocos (Keeling) Islands</option>
            <option value="CO">Colombia</option>
            <option value="KM">Comoros</option>
            <option value="CG">Congo</option>
            <option value="CD">Congo, the Democratic Republic of the</option>
            <option value="CK">Cook Islands</option>
            <option value="CR">Costa Rica</option>
            <option value="CI">Côte d'Ivoire</option>
            <option value="HR">Croatia</option>
            <option value="CU">Cuba</option>
            <option value="CW">Curaçao</option>
            <option value="CY">Cyprus</option>
            <option value="CZ">Czech Republic</option>
            <option value="DK">Denmark</option>
            <option value="DJ">Djibouti</option>
            <option value="DM">Dominica</option>
            <option value="DO">Dominican Republic</option>
            <option value="EC">Ecuador</option>
            <option value="EG">Egypt</option>
            <option value="SV">El Salvador</option>
            <option value="GQ">Equatorial Guinea</option>
            <option value="ER">Eritrea</option>
            <option value="EE">Estonia</option>
            <option value="ET">Ethiopia</option>
            <option value="FK">Falkland Islands (Malvinas)</option>
            <option value="FO">Faroe Islands</option>
            <option value="FJ">Fiji</option>
            <option value="FI">Finland</option>
            <option value="FR">France</option>
            <option value="GF">French Guiana</option>
            <option value="PF">French Polynesia</option>
            <option value="TF">French Southern Territories</option>
            <option value="GA">Gabon</option>
            <option value="GM">Gambia</option>
            <option value="GE">Georgia</option>
            <option value="DE">Germany</option>
            <option value="GH">Ghana</option>
            <option value="GI">Gibraltar</option>
            <option value="GR">Greece</option>
            <option value="GL">Greenland</option>
            <option value="GD">Grenada</option>
            <option value="GP">Guadeloupe</option>
            <option value="GU">Guam</option>
            <option value="GT">Guatemala</option>
            <option value="GG">Guernsey</option>
            <option value="GN">Guinea</option>
            <option value="GW">Guinea-Bissau</option>
            <option value="GY">Guyana</option>
            <option value="HT">Haiti</option>
            <option value="HM">Heard Island and McDonald Islands</option>
            <option value="VA">Holy See (Vatican City State)</option>
            <option value="HN">Honduras</option>
            <option value="HK">Hong Kong</option>
            <option value="HU">Hungary</option>
            <option value="IS">Iceland</option>
            <option value="IN">India</option>
            <option value="ID">Indonesia</option>
            <option value="IR">Iran, Islamic Republic of</option>
            <option value="IQ">Iraq</option>
            <option value="IE">Ireland</option>
            <option value="IM">Isle of Man</option>
            <option value="IL">Israel</option>
            <option value="IT">Italy</option>
            <option value="JM">Jamaica</option>
            <option value="JP">Japan</option>
            <option value="JE">Jersey</option>
            <option value="JO">Jordan</option>
            <option value="KZ">Kazakhstan</option>
            <option value="KE">Kenya</option>
            <option value="KI">Kiribati</option>
            <option value="KP">Korea, Democratic People's Republic of</option>
            <option value="KR">Korea, Republic of</option>
            <option value="KW">Kuwait</option>
            <option value="KG">Kyrgyzstan</option>
            <option value="LA">Lao People's Democratic Republic</option>
            <option value="LV">Latvia</option>
            <option value="LB">Lebanon</option>
            <option value="LS">Lesotho</option>
            <option value="LR">Liberia</option>
            <option value="LY">Libya</option>
            <option value="LI">Liechtenstein</option>
            <option value="LT">Lithuania</option>
            <option value="LU">Luxembourg</option>
            <option value="MO">Macao</option>
            <option value="MK">Macedonia, the former Yugoslav Republic of</option>
            <option value="MG">Madagascar</option>
            <option value="MW">Malawi</option>
            <option value="MY">Malaysia</option>
            <option value="MV">Maldives</option>
            <option value="ML">Mali</option>
            <option value="MT">Malta</option>
            <option value="MH">Marshall Islands</option>
            <option value="MQ">Martinique</option>
            <option value="MR">Mauritania</option>
            <option value="MU">Mauritius</option>
            <option value="YT">Mayotte</option>
            <option value="MX">Mexico</option>
            <option value="FM">Micronesia, Federated States of</option>
            <option value="MD">Moldova, Republic of</option>
            <option value="MC">Monaco</option>
            <option value="MN">Mongolia</option>
            <option value="ME">Montenegro</option>
            <option value="MS">Montserrat</option>
            <option value="MA">Morocco</option>
            <option value="MZ">Mozambique</option>
            <option value="MM">Myanmar</option>
            <option value="NA">Namibia</option>
            <option value="NR">Nauru</option>
            <option value="NP">Nepal</option>
            <option value="NL">Netherlands</option>
            <option value="NC">New Caledonia</option>
            <option value="NZ">New Zealand</option>
            <option value="NI">Nicaragua</option>
            <option value="NE">Niger</option>
            <option value="NG">Nigeria</option>
            <option value="NU">Niue</option>
            <option value="NF">Norfolk Island</option>
            <option value="MP">Northern Mariana Islands</option>
            <option value="NO">Norway</option>
            <option value="OM">Oman</option>
            <option value="PK">Pakistan</option>
            <option value="PW">Palau</option>
            <option value="PS">Palestinian Territory, Occupied</option>
            <option value="PA">Panama</option>
            <option value="PG">Papua New Guinea</option>
            <option value="PY">Paraguay</option>
            <option value="PE">Peru</option>
            <option value="PH">Philippines</option>
            <option value="PN">Pitcairn</option>
            <option value="PL">Poland</option>
            <option value="PT">Portugal</option>
            <option value="PR">Puerto Rico</option>
            <option value="QA">Qatar</option>
            <option value="RE">Réunion</option>
            <option value="RO">Romania</option>
            <option value="RU">Russian Federation</option>
            <option value="RW">Rwanda</option>
            <option value="BL">Saint Barthélemy</option>
            <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
            <option value="KN">Saint Kitts and Nevis</option>
            <option value="LC">Saint Lucia</option>
            <option value="MF">Saint Martin (French part)</option>
            <option value="PM">Saint Pierre and Miquelon</option>
            <option value="VC">Saint Vincent and the Grenadines</option>
            <option value="WS">Samoa</option>
            <option value="SM">San Marino</option>
            <option value="ST">Sao Tome and Principe</option>
            <option value="SA">Saudi Arabia</option>
            <option value="SN">Senegal</option>
            <option value="RS">Serbia</option>
            <option value="SC">Seychelles</option>
            <option value="SL">Sierra Leone</option>
            <option value="SG">Singapore</option>
            <option value="SX">Sint Maarten (Dutch part)</option>
            <option value="SK">Slovakia</option>
            <option value="SI">Slovenia</option>
            <option value="SB">Solomon Islands</option>
            <option value="SO">Somalia</option>
            <option value="ZA">South Africa</option>
            <option value="GS">South Georgia and the South Sandwich Islands</option>
            <option value="SS">South Sudan</option>
            <option value="ES">Spain</option>
            <option value="LK">Sri Lanka</option>
            <option value="SD">Sudan</option>
            <option value="SR">Suriname</option>
            <option value="SJ">Svalbard and Jan Mayen</option>
            <option value="SZ">Swaziland</option>
            <option value="SE">Sweden</option>
            <option value="CH">Switzerland</option>
            <option value="SY">Syrian Arab Republic</option>
            <option value="TW">Taiwan, Province of China</option>
            <option value="TJ">Tajikistan</option>
            <option value="TZ">Tanzania, United Republic of</option>
            <option value="TH">Thailand</option>
            <option value="TL">Timor-Leste</option>
            <option value="TG">Togo</option>
            <option value="TK">Tokelau</option>
            <option value="TO">Tonga</option>
            <option value="TT">Trinidad and Tobago</option>
            <option value="TN">Tunisia</option>
            <option value="TR">Turkey</option>
            <option value="TM">Turkmenistan</option>
            <option value="TC">Turks and Caicos Islands</option>
            <option value="TV">Tuvalu</option>
            <option value="UG">Uganda</option>
            <option value="UA">Ukraine</option>
            <option value="AE">United Arab Emirates</option>
            <option value="GB">United Kingdom</option>
            <option value="US">United States</option>
            <option value="UM">United States Minor Outlying Islands</option>
            <option value="UY">Uruguay</option>
            <option value="UZ">Uzbekistan</option>
            <option value="VU">Vanuatu</option>
            <option value="VE">Venezuela, Bolivarian Republic of</option>
            <option value="VN">Viet Nam</option>
            <option value="VG">Virgin Islands, British</option>
            <option value="VI">Virgin Islands, U.S.</option>
            <option value="WF">Wallis and Futuna</option>
            <option value="EH">Western Sahara</option>
            <option value="YE">Yemen</option>
            <option value="ZM">Zambia</option>
            <option value="ZW">Zimbabwe</option>
          </select></div>
          <div class="popup__body__field" v-if="!activeDefault && activeAddress.billoaddress">
            <label>{{ $t('account.defaultpickup') }}<input
                type="checkbox" v-model="activeAddress.defaultaddress"></label></div>
          <div class="popup__body__field" v-if="!activeInvoice && activeAddress.billoaddress">
            <label>{{ $t('account.defaultinvoice') }}<input
                type="checkbox" v-model="activeAddress.invoiceaddress"></label></div>
        </div>
        <div class="popup__controls">
          <button class="popup__btn popup__btn--decline" v-if="userData.addresses.length > 1"
                  @click="deleteAddress">{{ $t('account.delete') }}
          </button>
          <button class="popup__btn popup__btn--accept" @click="onEditAddressSubmit">{{ $t('account.save') }}
          </button>
        </div>
      </div>
    </div>

    <div class="popup" v-if="editUserVisible">
      <div class="popup__wrapper">
        <button class="popup__close" @click="editUserVisible=false">
          <fa-icon icon="times"></fa-icon>
        </button>
        <p class="popup__title">{{ $t('account.editinfo') }}</p>
        <p class="popup__text">{{ $t('account.editinfotext') }}</p>
        <div class="popup__body">
          <div class="popup__body__field">
            <label>{{ $t('booking.firstname') }}</label>
            <input type="text" v-model="editUser.firstname"
                   @input="fNameValid=true"
                   :class="{'error':!fNameValid}"
                   :placeholder="$t('booking.firstname')">
          </div>
          <div class="popup__body__field">
            <label>{{ $t('booking.name') }}</label>
            <input type="text" v-model="editUser.lastname"
                   @input="lNameValid=true"
                   :class="{'error':!lNameValid}"
                   :placeholder="$t('booking.name')">
          </div>
          <div class="popup__body__field">
            <label>{{ $t('booking.email') }}</label>
            <input type="text" v-model="editUser.email"
                   @input="emailValid=true"
                   :class="{'error':!emailValid}"
                   :placeholder="$t('booking.email')">
          </div>
          <div class="popup__body__field">
            <label>{{ $t('booking.phone') }}</label>
            <vue-tel-input :preferredCountries="['BE','NL','FR','LU']" v-model="editUser.phone"
                           @input="phoneInputValid=true"
                           :class="{'error':!phoneInputValid}"
                           @validate="handlePhoneValidate"></vue-tel-input>
          </div>
        </div>
        <div class="popup__controls">
          <button class="popup__btn popup__btn--accept" :class="{'disabled':userEditing}"
                  @click="onEditUserSubmit">{{ $t('account.save') }}
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {VueTelInput} from 'vue-tel-input';

export default {
  name: "Account",

  components: {
    VueTelInput
  },

  data() {
    return {
      loaded: false,
      user: {},
      editUser: {},
      profileError: null,
      activeAddress: null,
      activeDefault: false,
      activeInvoice: false,
      newAddress: null,
      loadingOrders: false,
      addressLoading: false,
      addressError: null,
      ordersError: null,
      pageSize: 5,
      currentPage: 1,

      ecode: this.$route.query.code ? this.$route.query.code : "",
      resetError: false,
      isResetSent: false,

      ecodeError: false,
      editUserVisible: false,

      phoneValid: true,
      phoneInputValid: true,
      fNameValid: true,
      lNameValid: true,
      emailValid: true,

      userEditing: false
    };
  },

  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
    hasOrders() {
      return this.$store.getters['user/hasOrders'];
    },
    orders() {
      return this.$store.getters['user/orders'];
    },
    ordersPages() {
      return this.$store.getters['user/ordersPages'];
    },
    ordersAmount() {
      return this.hasOrders ? this.orders.length : 0;
    }
  },

  created() {
    this.fetchData();
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  methods: {
    fetchData() {
      this.loadOrders();
      this.$store.dispatch('user/getUserData');
    },

    async loadOrders() {
      const data = {
        PageSize: this.pageSize,
        PageNumber: this.currentPage
      }
      this.ordersError = null;
      this.loadingOrders = true;
      try {
        await this.$store.dispatch('user/getUserOrders', data);
      } catch (e) {
        this.ordersError = e;
      }
      const resultbody = document.getElementById('result-body');
      if (resultbody) {
        resultbody.scrollTop = 0;
      }
      this.loadingOrders = false;
    },

    addAddress() {
      const newAddress = {
        id: this.userData.user.id,
        street: "",
        streetnr: "",
        zip: "",
        city: "",
        country: "BE",
        defaultaddress: 0,
        invoiceaddress: 0
      }
      this.newAddress = newAddress;
    },

    onNewAddressCancel() {
      this.newAddress = null;
    },

    async onNewAddressSubmit() {
      this.addressError = null;
      const adr = this.newAddress;
      if (!adr.street || !adr.streetnr || !adr.zip || !adr.city) {
        this.addressError = 'error!';
        return;
      }
      this.addressLoading = true;
      try {
        await this.$store.dispatch('user/newAddress', adr);
        this.activeAddress = null;
        this.fetchData();
        this.newAddress = null;
      } catch (e) {
        this.addressError = 'error!';
      }
      this.addressLoading = false;
    },

    editAddress(item) {
      this.activeAddress = {...item};
      this.activeDefault = item.defaultaddress;
      this.activeInvoice = item.invoiceaddress;
    },

    async onEditAddressSubmit() {
      this.addressError = null;
      const adr = this.activeAddress;
      if (!adr.street || !adr.streetnr || !adr.zip || !adr.city) {
        this.addressError = 'error!';
        return;
      }
      this.addressLoading = true;
      try {
        await this.$store.dispatch('user/editAddress', adr);
        this.activeAddress = null;
        this.fetchData();
        this.newAddress = null;
      } catch (e) {
        this.addressError = 'error!';
      }
      this.addressLoading = false;
    },
    async deleteAddress() {
      this.addressLoading = true;
      try {
        await this.$store.dispatch('user/deleteAddress', this.activeAddress.id);
        this.activeAddress = null;
        this.fetchData();
        this.newAddress = null;
      } catch (e) {
        this.addressError = 'error!';
      }
      this.addressLoading = false;
    },

    prevPage() {
      this.currentPage--;
      this.loadOrders();
    },
    nextPage() {
      this.currentPage++;
      this.loadOrders();
    },

    showOrder(bonnr, lang, klant) {
      let template = 'billoconfirmation';
      if (klant === 'BILLOEVENTS') {
        template = 'billoeventconfirmation'
      }
      const link = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/templates/${template}.php?id=${bonnr}&taal=${lang.toUpperCase()}`;
      const encoded = escape(link);
      const dataLink = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/pdf/generate_pdf.json?url=${encoded}`;
      window.open(dataLink);
    },

    async resetPassword() {
      this.isResetSent = false;
      this.resetError = false;
      const response = await this.$store.dispatch('user/recoverPassword', this.userData.user.email);
      if (response.status) {
        this.isResetSent = true;
        return;
      }
      this.resetError = true;
    },

    async submitCode() {
      this.ecodeError = false;
      try {
        await this.$store.dispatch('user/addEcode', {code: this.ecode});
        this.currentPage = 1;
        this.fetchData();
        this.ecode = "";
      } catch (e) {
        this.ecodeError = e;
      }
    },

    showEditUser() {
      this.editUser = {...this.userData.user};
      this.editUserVisible = true;
      this.userEditing = false;
    },

    async onEditUserSubmit() {
      this.phoneInputValid = this.phoneValid;
      this.emailValid = this.mailValidate(this.editUser.email);
      this.fNameValid = this.editUser.firstname && this.editUser.firstname !== "";
      this.lNameValid = this.editUser.lastname && this.editUser.lastname !== "";
      if (this.fNameValid && this.lNameValid && this.phoneValid && this.emailValid) {
        this.userEditing = true;
        try {
          await this.$store.dispatch('user/editUser', this.editUser);
          this.editUser = null;
          this.fetchData();
        } catch (e) {
          this.profileError = 'error';
        }
        this.userEditing = false;
      } else {
        this.profileError = 'error!'
      }
    },

    handlePhoneValidate(value) {
      this.phoneValid = value.valid && value.type === "mobile";
    },

    mailValidate(email) {
      const regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return email.match(regex);
    },

    logoutUser() {
      this.$store.dispatch('logout');
      this.$router.replace('/login');
    }
  }
}
</script>